////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(11, 49, 66, 1);
}
.bg-secondaryColor {
  background-color: rgba(190, 197, 173, 1);
}
.bg-thirdColor {
  background-color: rgba(190, 197, 173, 1);
}
.bg-fourthColor {
  background-color: rgba(48, 116, 88, 1);
}
.bg-fifthColor {
  background-color: rgba(89, 143, 121, 1);
}
.bg-sixthColor {
  background-color: rgba(11, 49, 66, 1);
}
.bg-seventhColor {
  background-color: rgba(11, 49, 66, 1);
}
